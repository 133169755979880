import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../hooks/skeletonDisplay";

const Slider = () => {
  return (
    <div className={Styles.slider1}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/homewelcome.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>
                Welcome to <br /> Star Leadership Academy
              </h1>
            </Zoom>
            <div className={Styles.buttonContainer}>
              <button>Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
