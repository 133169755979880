import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../hooks/skeletonDisplay";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Empowering minds, shaping leaders, igniting futures.</h1>
            <SkeletonDisplay>
              <img src="/aboutus/idea.png" alt="Star Leadership Academy" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h2>Mission</h2>
              <p>
                To cultivate a generation of empowered individuals through
                innovative education, fostering leadership, resilience, and a
                passion for lifelong learning.
              </p>
              <h2>Vision</h2>
              <p>
                Star Leadership Academy envisions a world where every child has
                the opportunity to realize their full potential, equipped with
                the skills, knowledge, and values to lead with integrity and
                make meaningful contributions to their communities and beyond
              </p>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;
