import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider2 = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>What Star Leadership Academy Education Entails</h1>

              <p>
                Traditionally, play and learning have been seen as opposites
                with the freedom of play on one hand and learning organized by
                adults on the other. However, at Star Leadership Academy, we
                have identified that playful techniques can yield the best
                results when it comes to early years’ education and care
                service. As such, we lay great emphasis on structured learning
                through interactive fun methods to ensure all aspects of a
                child’s social, emotional, physical, and cognitive development
                needs are met.
              </p>
            </Zoom>
            <Zoom>
              <p>
                Star Leadership Academy's teaching is shaped by the Kenyan
                Competency based curriculum which focuses on real-world
                applications, critical thinking, problem solving and holistic
                development. The Curriculum prepares learners with relevant
                skills and knowledge to help them prosper.
              </p>
            </Zoom>
           
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/boysplaying2.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider2;
