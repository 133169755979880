import React from "react";
import Styles from "./Styles.module.css";
import ShowCase from "./showcase/showcase";

const Slider4 = () => {
  return (
    <div className={Styles.slider4}>
      <div className={Styles.mainContainer}>
       <ShowCase/>
      </div>
    </div>
  );
};

export default Slider4;
