import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import { ArrowCircleDown } from "@mui/icons-material";

const Slider6 = () => {
  return (
    <div className={Styles.slider6}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <img src="/aboutus/ourhistory.png" alt="Star Leadership Academy" />
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.arrowPointer6}>
                <button>2022</button> <ArrowCircleDown />
              </div>

              <h1>Our History</h1>
              <p>
                Star Leadership Academy emerged from the humble background of
                its founder, who initially welcomed ten students into her home.
                Through perseverance and a commitment to excellence, the school
                quickly expanded, accommodating 80 learners and employing five
                teachers by the end of 2022. This growth reflects the founder's
                relentless vision of empowering young learners and creating a
                nurturing environment for their holistic development.
              </p>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider6;
