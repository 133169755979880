// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "About Star Link Academy",
    path: "/About Us",
  },
  {
    title: "Academics",
    path: "Academics",
  },
  {
    title: "Activities",
    path: "Activities",
  },
  {
    title: "Resources",
    path: "/Resources",
  },
  {
    title: "Donate",
    path: "/Donate",
  },
  { title: "Contact us", path: "/Contact Us" },
];

export default navLinks;
