import React from "react";
import Styles from "./Styles.module.css";
import Slider2 from "./resources/Slider2";
import Slider3 from "./resources/Slider3";
import Slider4 from "./resources/Slider4";
import Slider5 from "./resources/Slider5";
import Slider6 from "./resources/Slider6";
import Slider7 from "./resources/Slider7";
import Slider8 from "./resources/Slider8";
import Slider9 from "./resources/Slider9";

function Resources() {
  return (
    <div>
      <div className={Styles.slider2}>
        <div className={Styles.mainContainer}>
          <img src="/aboutus/boysrunning.png" alt="Star Link Academy" />
          <button>Resources</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Slider2 />
        <Slider3 />
        <Slider4 />
        <Slider5 />
        <Slider6 />
        <Slider7 />
        <Slider8 />
        <Slider9 />
      </div>
    </div>
  );
}

export default Resources;
