import React from "react";

function Nomatch() {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Page Not found</h1>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          margin: " 0 auto",
        }}
      >
        <img
          style={{ width: "50%" }}
          src="/aboutus/homewelcome.png"
          alt="Star Leadership Academy"
        />
      </div>
    </div>
  );
}

export default Nomatch;
