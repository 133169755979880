import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Zoom } from "react-awesome-reveal";


const Slider6 = () => {
  return (
    <div className={Styles.slider6}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/girlstaring.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h2>What We Learn?</h2>
              <p>
                The primary years framework follows an adapted form of the
                Kenyan Curriculum, whereby students study various subjects,
                including Mathematics, English, Science, Geography, History,
                Art, Physical Education, Music, a Modern Foreign Language, and
                ICT.
              </p>
              <p>
                Primary years students undertake assessments in English,
                Mathematics and Science at various stages to monitor pupil
                standards and ensure learning objectives are met. In addition,
                children sit a major assessment at the end of Year 8 to mark the
                transition to Secondary School at age 13+.
              </p>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider6;
