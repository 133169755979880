import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/boysmiling.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Why We Learn?</h1>
              <p>
                Attending a school, like Star Leadership Academy, ensures your
                child benefits from an approach focusing on the holistic
                development of the child. While education and academics are
                important to us, we strive to provide a balanced environment
                where children feel secure and not overwhelmed. Learning in our
                school is a joyful experience where learners nurture their
                natural curiosity and embark on an adventurous lifelong journey
                of learning.
              </p>
              <p>
                We strive to build an inviting atmosphere where learners feel
                welcome and secure, where they are encouraged to try new things
                without the fear of failure, and where new experiences are a
                part of each day.
              </p>{" "}
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;
