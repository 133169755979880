const ShowCases = [
  {
    activityId: "1",
    title: "Our Heart Culture",
    image: "/images/activity1.png",
    description:
      "Our Heart Culture nurtures not only academic growth but also emotional intelligence and resilience. It's the heartbeat of our school, propelling us towards a future where leadership is defined by empathy, integrity, and genuine human connection. Welcome to Star Leadership Academy, where hearts lead the way.",
    link: "/",
  },
  {
    activityId: "2",
    title: "Playful Learning",
    image: "/images/activity2.png",
    description:
      "Our approach seamlessly integrates structured play with academic rigour, ensuring that learning is both engaging and effective. Through hands-on exploration and collaborative activities, students develop critical thinking, problem-solving skills, and a lifelong love for learning.  meaningful contributions to their communities and beyond",
    link: "/",
  },
  {
    activityId: "3",
    title: "Holistic Child Development",
    image: "/images/activity3.png",
    description:
      "Our holistic approach ensures that every child receives personalized attention, cultivating a well-rounded individual equipped for success in all aspects of life. ",
    link: "/",
  },
];

export { ShowCases };
