import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";

const Slider4 = () => {
  return (
    <div className={Styles.slider4}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Meet Our Founder</h1>
            </Zoom>
            <p>
              “At Star Leadership Academy, we believe in you. You're not just a
              learner; you're a star , a future leader, equipped with knowledge,
              integrity, and resilience. Together, we'll unlock your potential
              and inspire greatness. Your journey starts here, shaping a world
              where possibilities are endless."
            </p>

            <button>Learn More</button>
          </div>
          <div className={Styles.productCard}>
            <img src="/aboutus/director.png" alt="Star Leadership Academy" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider4;
