import React, { useState, useEffect } from "react";
import Styles from "./styles1.module.css";
import { TestMonials } from "./testmonialData";
import SkeletonVariant from "../../hooks/SkeletonVariant";
import StarRating from "../../hooks/starrating";

function TestMonial() {
  const [activity, setActivity] = useState([]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await delay(2000);
        setActivity(TestMonials);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={Styles.themesContainer}>
      {!activity[0] ? (
        <>
          {data.map((_, index) => (
            <div key={index}>
              <SkeletonVariant />
            </div>
          ))}
        </>
      ) : (
        activity.map((showcase, index) => (
          <div>
            <div
              className={Styles.productCard}
              style={{ backgroundImage: `"url(/${showcase.image})"}` }}
              key={index}
            >
              <h5>{showcase.title}</h5>
              <p>{showcase.description}</p>
              <div style={{textAlign:"center"}}>
                {showcase.stars.map((item, index) => (
                  <div key={index}>
                    <StarRating count={item.count} imgSrc={item.starsimage} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default TestMonial;
const data = [1, 2, 3];
