import React from "react";
import Styles from "./Styles.module.css";
import TestMonial from "./testimonials/testmonial";

const Slider5 = () => {
  return (
    <div className={Styles.slider4}>
      <div className={Styles.mainContainer}>
        <TestMonial />
      </div>
    </div>
  );
};

export default Slider5;
