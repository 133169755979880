import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import { ArrowCircleDown } from "@mui/icons-material";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider8 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.arrowPointer8}>
                <button>2024</button> <ArrowCircleDown />
              </div>

              <h1>Onwards and Beyond</h1>
              <p>
                With an unwavering commitment to excellence, Star Leadership
                Academy strives to refine its educational offerings, ensuring
                each pupil receives a transformative learning experience.
              </p>
              <p>
                Anchored by a team of passionate educators, the school
                cultivates an environment where curiosity flourishes and
                potential blossoms.
              </p>
              <p>
                We commit to prioritizing affordability, as we open doors to
                education for all, by fostering a more inclusive and equitable
                society.
              </p>
              <p>
                Star Leadership Academy's journey onwards is one of boundless
                possibilities, propelled by the collective aspirations of its
                community and the promise of a brighter future for all learners.
              </p>
            </Zoom>
          </div>{" "}
          <div className={Styles.productCard}>
            <SkeletonDisplay>
            <img
              src="/aboutus/teacherswingingwithkids.png"
              alt="Star Leadership Academy"
            /></SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider8;
