import React from "react";
import Styles from "./Styles.module.css";
import Slider2 from "./academicsliders/Slider2";
import Slider3 from "./academicsliders/Slider3";
import Slider4 from "./academicsliders/Slider4";
import Slider5 from "./academicsliders/Slider5";
import Slider6 from "./academicsliders/Slider6";
import Slider7 from "./academicsliders/Slider7";
import Slider9 from "./academicsliders/Slider9";
import SkeletonDisplay from "../hooks/skeletonDisplay";

function Academics() {
  return (
    <div>
      <div className={Styles.slider2}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img src="/aboutus/academics.png" alt="Star Link Academy" />
          </SkeletonDisplay>
          <button>Academics</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Slider2 />
        <Slider3 />
        <Slider4 />
        <Slider6 />
        <Slider7 />
        <Slider5 />
        <Slider9 />
      </div>
    </div>
  );
}

export default Academics;
