import React from "react";
import Styles from "./Styles.module.css";
import Slider10 from "./aboutussliders/Slider10";
import SkeletonDisplay from "../hooks/skeletonDisplay";

function Donate() {
  return (
    <div>
      <div className={Styles.slider2}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img src="/aboutus/donate.png" alt="Star Link Academy" />
          </SkeletonDisplay>
          <button>Donate</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Slider10 />
      </div>
    </div>
  );
}

export default Donate;
