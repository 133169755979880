import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>About Senior Primary School</h1>
              <p>
                Our specialized curriculum at Senior Primary School grows
                passion in Science, Engineering, Technology, and Mathematics
                (STEM).We equip our learners with the skills and mindset to
                thrive in a rapidly evolving world. Through hands-on projects,
                real-world applications, and interdisciplinary learning, we
                inspire curiosity and foster problem-solving abilities essential
                for future success. At Senior Primary School, we go beyond
                textbooks, nurturing a passion for innovation and creativity in
                every student.{" "}
              </p>
              <p>
                Join us in shaping the next generation of STEM leaders, prepared
                to tackle global challenges and drive positive change. Welcome
                to a future of limitless possibilities at Senior Primary School.
              </p>{" "}
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/girlswithbasketball.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;
