const TestMonials = [
  {
    activityId: "1",
    title: "Ali’s - Parent",
    image: "/images/testmonialblack.png",
    stars: [{ count: 4, starsimage: "/images/star.png" }],
    description:
      "Star Leadership Academy transformed my son's outlook on learning. The holistic approach instilled confidence and a love for education. Truly grateful for the supportive environment.",
    link: "/",
  },
  {
    activityId: "2",
    title: "Mohamed’s- Parent",
    image: "/images/testmonialgreen.png",
    stars: [{ count:3, starsimage: "/images/star.png" }],
    description:
      "Exceptional! Star Leadership Academy nurtured my daughter's talents and character. The blend of academics and values education is remarkable. Highly recommend!",
    link: "/",
  },
  {
    activityId: "3",
    title: "Said’s - Parent",
    image: "/images/testmonialblue.png",
    stars: [{ count: 5, starsimage: "/images/star.png" }],
    description:
      "Star Leadership Academy exceeded my expectations. My child thrived academically and emotionally. The emphasis on holistic development sets this school apart. Grateful for the positive impact ",
    link: "/",
  },
];

export { TestMonials };
