import React from "react";

const StarRating = ({ count, imgSrc }) => {
  // Ensure count is a positive integer
  const starCount = Math.max(0, parseInt(count, 10) || 0);

  // Generate an array of star image elements
  const stars = Array.from({ length: starCount }, (_, index) => (
    <img key={index} src={imgSrc} alt="Star" className="star" />
  ));

  return <div className="star-container">{stars}</div>;
};

export default StarRating;
