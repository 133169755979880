import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";


const Slider5 = () => {
  return (
    <div className={Styles.slider5}>
      <div className={Styles.mainContainer}>
        <div className={Styles.slider5Title}>
          <Zoom>
            <h1>Safe & Inclusive Environment</h1>
          </Zoom>
        </div>
        <div className={Styles.slider5themesContainer}>
          <div >
            <Zoom>
              <div className={Styles.subSpan}>
                <img src="aboutus/playground.png"  alt="Star Leadership Academy"/>
              </div>
            </Zoom>
          </div>{" "}
          <div >
            <Zoom>
              <div className={Styles.subSpan}>
                <img src="aboutus/classrooms.png"  alt="Star Leadership Academy"/>
              </div>
            </Zoom>
          </div>
          <div >
            <Zoom>
              <div className={Styles.subSpan}>
                <img src="aboutus/ideas.png" alt="Star Leadership Academy" />
              </div>
            </Zoom>
          </div>
        </div>
        <div className={Styles.slider5themesContainer}>
          <div className={Styles.subContainer5text}>
            <Zoom>
              <h5>PlayGround</h5>
              <p>
                We have enticing outdoor play areas to engage children in
                various physical activities, sports, and play.{" "}
              </p>
            </Zoom>
          </div>{" "}
          <div className={Styles.subContainer5text}>
            <Zoom>
              <h5>Age-appropriate Classrooms</h5>
              <p>
                Our learning spaces are designed to meet the age-appropriate
                learning requirements of each child group.
              </p>
            </Zoom>
          </div>
          <div className={Styles.subContainer5text}>
            <Zoom>
              <h5>Multi-sensory Learning</h5>
              <p>
                We have enticing outdoor play areas to engage children in
                various physical activities, sports, and play.{" "}
              </p>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider5;
