import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../hooks/skeletonDisplay";

const Slider2 = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/director.png" alt="Star Leadership Academy" />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <h3>Who is Star Leadership Academy ?</h3>
              <p>
                Nestled in the heart of Badhan District in Somalia, Star
                Leadership Academy stands as a beacon of educational excellence.
                Catering to both boys and girls aged between 3 to 12 years, it
                offers a unique blend of British and Montessori curricula,
                ensuring a holistic approach to primary education.
              </p>
              <p>
                At Star Leadership Academy, the focus extends beyond mere
                academics. Here, teachers are not just educators but mentors,
                nurturing young minds to become future leaders. With
                state-of-the-art facilities and a dedicated faculty, the academy
                pivots its value proposition towards empowering learners from an
                early age.
              </p>
              <p>
                Through innovative teaching methodologies and a nurturing
                environment, Star Leadership Academy instills not just knowledge
                but also values of integrity, resilience, and leadership. It's
                not just a school; it's a journey towards excellence, shaping
                the leaders of tomorrow.
              </p>
            </Zoom>
            <div>
              <button className={Styles.infobtnblue}>Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider2;
