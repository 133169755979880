import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import { ArrowCircleDown } from "@mui/icons-material";

const Slider7 = () => {
  return (
    <div className={Styles.slider7}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.arrowPointer7}>
                <button>2023</button> <ArrowCircleDown />
              </div>
              <h1>Building on Success</h1>
              <p>
                Buoyed by a wave of positive feedback from the community, Star
                Leadership surged ahead in 2023, capitalizing on its goodwill.
                With a resounding vote of confidence, the school expanded its
                pupils population to 180 children and bolstered its teaching
                staff to 15, accommodating eight bustling classes.
              </p>
              <p>
                This remarkable growth was a testament to the school's
                commitment to affordability, unparalleled quality of education,
                a dedicated team of educators, and the array of enriching
                opportunities it offered. Riding on this momentum, Star
                Leadership Academy continued to shine as a beacon of excellence
                in education, setting new standards and inspiring generations to
                come.
              </p>
              <p>
                We commit to prioritizing affordability, as we open doors to
                education for all, by fostering a more inclusive and equitable
                society.
              </p>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <img
              src="/aboutus/buildingonsucess.png"
              alt="Star Leadership Academy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider7;
