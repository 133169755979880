import React from "react";
import Slider7 from "../slider/Slider7";

function Footer() {
  return (
    <div>
      <Slider7 />
    </div>
  );
}

export default Footer;
