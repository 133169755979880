import React from "react";
import Slider from "./components/slider/Slider";
import Slider2 from "./components/slider/Slider2";
import Slider3 from "./components/slider/Slider3";
import Slider4 from "./components/slider/Slider4";
import Slider5 from "./components/slider/Slider5";
import Slider6 from "./components/slider/Slider6";


function Home() {
  return (
    <div>
      <Slider />
      <Slider2 />
      <Slider3 />
      <Slider4 />
      <Slider5 />
      <Slider6 />
    
    </div>
  );
}

export default Home;
