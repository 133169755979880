import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../hooks/skeletonDisplay";

const Slider6 = () => {
  return (
    <div className={Styles.slider6}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Our Locations</h1>
              <p>
                We are located in Badhan district in the Sanaag region of
                Somalia.{" "}
              </p>
            </Zoom>
            <div className={Styles.buttonContainer}>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/images/Badhaan Map.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider6;
