import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Zoom } from "react-awesome-reveal";

const Slider7 = () => {
  return (
    <div className={Styles.slider7}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h2>Where We Learn?</h2>
              <p>
                We understand that a well-prepared playing environment is
                essential for quality learning, therefore, we utilize structure
                and systems of organization to ensure children are safe and
                protected.
              </p>
              <p>
                Our facilities include modern classrooms equipped with the
                latest educational technology, creating an interactive and
                engaging learning space. The school grounds are safe and
                welcoming, providing areas for both study and play.
              </p>
              <p>
                Community is at the heart of Star Leadership Academy.
                Collaborative spaces encourage teamwork and peer learning, while
                our dedicated teachers create a supportive atmosphere where
                every student feels valued. Here, learning extends beyond
                textbooks, fostering a culture of curiosity, creativity, and
                lifelong passion for knowledge.
              </p>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/girlssharing.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider7;
