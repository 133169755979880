import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.Navbar}>
      <div className={Styles.rightSide}>
        <Link to="/">
          <img src="aboutus/logo.png" alt="StarLeadershipAcademy" />
        </Link>
      </div>
      <div className={Styles.leftSide}>
        <AttentionSeeker>
          <Link to="/">
            <img src="aboutus/header.png" alt="StarLeadershipAcademy" />
          </Link>
        </AttentionSeeker>
      </div>
    </div>
  );
}

export default MainMenu;
