import { WrapText } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

// CSS styles for the banner
const bannerStyles = {
  position: "fixed",
  bottom: "0",
  width: "90%",
  backgroundColor: "#333",
  color: "#fff",
  textAlign: "left",
  textWrap: "wrap",
  marginRight: "1rem",
  padding: "1rem 5rem",
  zIndex: "1000",
  fontSize: "16px",
};

const buttonContainerStyles = {
  marginTop: "10px",
};

const buttonStyles = {
  margin: "0 10px",
  padding: "10px 20px",
  backgroundColor: "#007bff",
  border: "none",
  borderRadius: "4px",
  color: "#fff",
  cursor: "pointer",
};

const declineButtonStyles = {
  ...buttonStyles,
  backgroundColor: "#f44336", // red color for decline button
};

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div style={bannerStyles}>
      <div>
        This website stores cookies on your computer. These cookies are used to
        improve your website experience and provide more personalized services
        to you, both on this website and through other media. To find out more
        about the cookies we use, see our Privacy Policy.
      </div>
      <div>
        We won't track your information when you visit our site. But in order to
        comply with your preferences, we'll have to use just one tiny cookie so
        that you're not asked to make this choice again.
      </div>
      <div style={buttonContainerStyles}>
        <button style={buttonStyles} onClick={handleAccept}>
          Accept
        </button>
        <button style={declineButtonStyles} onClick={handleDecline}>
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
