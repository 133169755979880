import React, { useState } from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import {
  East,
  Facebook,
  Instagram,
  LinkedIn,
  WhatsApp,
} from "@mui/icons-material";
import {} from "react-icons/fa";

const Slider8 = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle form submission logic here
    console.log("Form submitted:", formData);

    // Reset form fields
    setFormData({
      email: "",
    });

    // Show the success message
    setSubmitted(true);

    // Optionally, hide the message after a few seconds
    setTimeout(() => {
      setSubmitted(false);
    }, 3000); // Hide after 3 seconds
  };
  const isFormDataFilled = formData.email !== "";

  const buttonStyle = {
    backgroundColor: isFormDataFilled ? "var(--starblue)" : "var(--starblack)",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: isFormDataFilled ? "pointer" : "not-allowed",
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
      in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className={Styles.slider8}
      style={{ backgroundColor: "var(--stargreen)!important" }}
    >
      <div className={Styles.mainContainer}>
        <div className={Styles.container8}>
          <div className={Styles.productCard}>
            <Zoom>
              <div className={Styles.footerHighlight}>
                <h3>
                  We Combine <br />
                  Puntland and Kenyan <br /> Primary School Curriculum{" "}
                </h3>
                <Link to="/">
                  <img src="/aboutus/logo.png" alt="Star Leadership Academy" />
                </Link>
              </div>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <h4>Quick Links</h4>
            {navLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Key Links</h4>
            {generalLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
        </div>
        <div className={Styles.signUp8}>
          <h3>Sign Up5 for Our Latest News</h3>
          <div>
            <form
              onSubmit={handleSubmit}
              className={Styles.signUpSubcontainer8}
            >
              <input
                placeholder="Your-email-address@domain.com"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <button
                type="submit"
                disabled={!isFormDataFilled}
                style={buttonStyle}
              >
                <East />
              </button>
            </form>
          </div>
          {submitted && (
            <div className={Styles.submittedForm}>
              Thanks, Successfully Submitted
            </div>
          )}
        </div>
        <div className={Styles.footer}>
          <p>
            Copyright : &copy; {new Date().getFullYear()} Star Leadership
            Academy . All Rights Reserved
          </p>
          <div className={Styles.socials}>
            <span>
              <Facebook />
            </span>
            <span>
              <WhatsApp />
            </span>
            <span>
              <Instagram />
            </span>
            <span>
              <LinkedIn />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider8;

const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "About Star Leadership Academy",
    path: "/About Us",
  },
  {
    title: "Academics : Our Programs",
    path: "/Academics",
  },
  {
    title: "Activities : Extra Curricular Activities",
    path: "/Activities",
  },
  {
    title: "Resources: Reports | Blogs | Downloads",
    path: "/Resources",
  },
  {
    title: "Donate: Sponsor a Leaner",
    path: "/Donate",
  },
  { title: "Contact Us", path: "/Contact Us" },
];

const generalLinks = [
  { title: "Careers", path: "/Careers" },
  { title: "Sitemap", path: "/Site Map" },
  { title: "Frequently Asked Questions", path: "/Frequently Asked Questions" },
  { title: "Home", path: "/" },
  { title: "Privacy Policy", path: "/Privacy Policy" },
  { title: "Terms of Service", path: "/Terms Of Service" },
];
