import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider2 = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>About Junior Primary School</h1>

              <p>
                Our nurturing environment fosters a love for learning and a
                foundation for future success. With dedicated teachers,
                interactive lessons, and a focus on holistic development, we
                empower young learners to explore, discover, and thrive. Welcome
                to a place where curiosity is celebrated, confidence is
                cultivated, and every child's potential is realized. Join us on
                the journey of education, growth, and endless possibilities
                where Little Minds Blossom.
              </p>
            </Zoom>
            <div>
              <button>Learn More</button>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/boysreading.png"
                alt="Star Leadership Academy"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider2;
