import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";
import { Forward } from "@mui/icons-material";

const Slider5 = () => {
  return (
    <div className={Styles.slider5}>
      <div className={Styles.mainContainer}>
        <div className={Styles.slider5Title}>
          <h1>Our Journey</h1>
        </div>
        <div className={Styles.slider5container}>
          <div className={Styles.subContainer5}>
            <Zoom>
              <div className={Styles.subSpan}>
                <h1>2022</h1>
                <p>80 Children</p>
                <p>5 Teachers</p>
              </div>
            </Zoom>
          </div>{" "}
          <div className={Styles.subContainer5Arrow}>
            <Forward fontSize="inherit" />
          </div>
          <div className={Styles.subContainer5}>
            <Zoom>
              <div className={Styles.subSpan}>
                <h1>2023</h1>
                <p>180 Children</p>
                <p>15 Teachers</p>
              </div>
            </Zoom>
          </div>
          <div className={Styles.subContainer5Arrow}>
            <Forward fontSize="inherit" />
          </div>
          <div className={Styles.subContainer5}>
            <Zoom>
              <div className={Styles.subSpan}>
                <h1>2024</h1>
                <p>600 Children</p>
                <p>35 Teachers</p>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider5;
