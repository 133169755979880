import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";

const Slider4 = () => {
  return (
    <div className={Styles.slider4}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h2>How We Learn?</h2>
              <p>
                At Star Leadership Academy, learners embark on a journey of
                discovery through a dynamic and interactive curriculum. We blend
                the best of Kenyan and Puntland curriculums to create an
                engaging learning environment where curiosity is encouraged, and
                creativity thrives. Each child is nurtured to explore their
                unique interests and talents, fostering a deep love for learning
                from an early age.
              </p>
            </Zoom>
            <Zoom>
              <p>
                Our dedicated teachers are mentors who guide students with
                personalized attention, ensuring that every learner's needs are
                met. Through hands-on activities, collaborative projects, and
                real-world applications, students develop critical thinking and
                problem-solving skills. This holistic approach not only
                strengthens their academic foundation but also builds confidence
                and resilience.
              </p>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img src="/aboutus/boyswriting.png" alt="Star Leadership Academy" />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider4;
